<template>
  <div class="dashboard-clinic-favourites">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <clinic-menu></clinic-menu>
            <div class="column is-12 right-column">
              <h3 class="title is-4 pb-4 txt-c-mobile">Laboratorios favoritos</h3>
              <div class="columns is-multiline">
                <div class="column is-12 mb-3">
                  <div class="laboratory-wide-card">
                    <div class="laboratory-icon">
                      <span class="icon-lab"></span>
                    </div>
                    <div class="lab-details">
                      <h3>Laboratorio Dental STLAB Srl </h3>
                      <p>Madrid</p>
                    </div>
                    <button class="button is-primary is-medium lab-button">
                      Listado de productos
                    </button>
                  </div>

                  <div class="laboratory-wide-card">
                    <div class="laboratory-icon">
                      <span class="icon-lab"></span>
                    </div>
                    <div class="lab-details">
                      <h3>Laboratorio Dental STLAB Srl </h3>
                      <p>Madrid</p>
                    </div>
                    <button class="button is-primary is-medium lab-button">
                      Listado de productos
                    </button>
                  </div>

                  <div class="laboratory-wide-card">
                    <div class="laboratory-icon">
                      <span class="icon-lab"></span>
                    </div>
                    <div class="lab-details">
                      <h3>Laboratorio Dental STLAB Srl </h3>
                      <p>Madrid</p>
                    </div>
                    <button class="button is-primary is-medium lab-button">
                      Listado de productos
                    </button>
                  </div>

                  <div class="laboratory-wide-card">
                    <div class="laboratory-icon">
                      <span class="icon-lab"></span>
                    </div>
                    <div class="lab-details">
                      <h3>Laboratorio Dental STLAB Srl </h3>
                      <p>Madrid</p>
                    </div>
                    <button class="button is-primary is-medium lab-button">
                      Listado de productos
                    </button>
                  </div>

                  <div class="laboratory-wide-card">
                    <div class="laboratory-icon">
                      <span class="icon-lab"></span>
                    </div>
                    <div class="lab-details">
                      <h3>Laboratorio Dental STLAB Srl </h3>
                      <p>Madrid</p>
                    </div>
                    <button class="button is-primary is-medium lab-button">
                      Listado de productos
                    </button>
                  </div>
                </div>
                <div class="column is-12 mb-3 all-btn-wrap">
                  <button class="button is-medium is-fullwidth is-primary is-outline" @click="loadNextPage()">Ver más laboratorio</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import ClinicMenu from '@/components/Clinic/ClinicMenu.vue'

import { mapGetters } from 'vuex'
export default {
  name: 'ClinicFavourites',
  components: {
    HeroImage,
    Footer,
    ClinicMenu
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      clinic: 'user/clinic',
      user: 'user/user'
    })
  },
  methods: {
    loadNextPage () {},
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  },
  created () {
    // fetch favourites
  }
}
</script>

<style scoped lang="scss">
.dashboard-clinic-favourites{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        flex-direction: column;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 80px;
          .laboratory-wide-card{
            display: flex;
            align-items: center;
            position: relative;
            background-color: #FFF;
            border-radius: 4px;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            transition: all .4s ease-out;
            width: 100%;
            padding: 20px;
            margin-bottom: 21px;
            text-align: left;
            .laboratory-icon{
              position: relative;
              height: 100px;
              width: 100px;
              background-color: #FFF;
              border-radius: 50%;
              box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
              margin-left: -70px;
              margin-right: 21px;
              span{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 3rem;
                &:before{
                  color: #57567C;
                }
              }
            }
            .lab-details{
              width: calc(100% - 241px);
              padding-right: 21px;
              h3{
                text-transform: uppercase;
                color: #57567C;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 23px;
              }
              p{
                color: #57567C;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 23px;
                padding-top: 10px;
              }
            }
            .lab-button{
              width: 195px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-clinic-info{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          .right-column{
            .is-half-mobile{
              width: 50%!important;
            }
          }
        }
      }
    }
  }
}
</style>
